<template>
  <div>
    <!-- 图片上传 -->
    <el-upload
      action=""
    :http-request="revampLoadShopDoor"
      :show-file-list="false"
      style="display: none"
      ref="upload"
      v-if="this.uploadUrl"
    >
    </el-upload>

    <el-upload
      action=""
       :http-request="addVideoLink"
      :show-file-list="false"
      style="display: none"
      ref="videoObj"
      v-if="this.uploadUrl"
    >
    </el-upload>
    <!-- 视频弹窗 -->
    <!-- <el-dialog
      width="650px"
      id="videoUpload"
      title="视频上传"
      :visible.sync="videoUploadTag"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="videoObj" :model="videoObj" label-width="80px">
        <el-form-item label="视频链接">
          <el-input
            v-model="videoObj.videoLink"
            placeholder="请输入视频链接"
            clearable
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="宽">
              <el-input-number
                :min="200"
                controls-position="right"
                v-model="videoObj.videoWidth"
                placeholder="请输入自定义宽"
                clearable
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="3">
            <el-form-item label="高">
              <el-input-number
                :min="200"
                controls-position="right"
                v-model="videoObj.videoHeight"
                placeholder="请输入自定义高"
                clearable
              ></el-input-number> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addVideoLink()">添加</el-button>
      </div>
    </el-dialog> -->
    <div class="editor" ref="editor" :style="styles"></div>
  </div>
</template>

<script>
import * as Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import getBrowserInfo from '../utils/BrowserName'
import { uuid } from 'vue-uuid';
// import { getToken } from "@/utils/auth";
// 注册自定义Video
import Video from "@/utils/quill-video";
Quill.register(Video, true);

export default {
  name: "Editor",
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: ""
    },
    /* 高度 */
    height: {
      type: Number,
      default: null
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false
    },
    /* 上传地址 */
    uploadUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    var than = this;
    return {
      //显示插入视频链接弹框的标识
      videoUploadTag: false,
      //弹框插入的视频链接记录
      videoObj: {
        videoLink: "",
        videoWidth: "",
        videoHeight: ""
      },
      
      Quill: null,
      currentValue: "",
      options: {
        theme: "snow",
        bounds: document.body,
        debug: "warn",
        modules: {
            imageDrop: false, // 拖动加载图片组件。
        imageResize: { //调整大小组件。
            displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },

          // 工具栏配置
          toolbar: {
            container: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
        ['link', 'image', 'video'],
        ['clean']                                           
            ],
            
            handlers: {
              video: value => {
                // 点击视频图标，弹出窗
                than.videoObj = {
                  videoLink: "",
                  wdith: "",
                  height: ""
                };
                than.videoUploadTag = true;
              }
            }
          }
        },
        placeholder: "请输入内容",
        readOnly: this.readOnly
      }
    };
  },
  computed: {
    styles() {
      let style = {};
      if (this.minHeight) {
        style.minHeight = `${this.minHeight}px`;
      }
      if (this.height) {
        style.height = `${this.height}px`;
      }
      return style;
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val !== this.currentValue) {
          this.currentValue = val === null ? "" : val;
          if (this.Quill) {
            this.Quill.pasteHTML(this.currentValue);
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.Quill = null;
  },
  methods: {
    init() {
      const editor = this.$refs.editor;
      this.Quill = new Quill(editor, this.options);
      // 如果设置了自定上传地址则义图片上传事件
        let toolbar = this.Quill.getModule("toolbar");
        toolbar.addHandler("image", value => {
          this.uploadType = "image";
           
          if (value) {
            
            this.$refs.upload.$children[0].$refs.input.click();
        
          } else {
            this.quill.format("image", false);
          }
        });
        toolbar.addHandler("video", value => {
  
          this.uploadType = "video";
          if (value) {
            this.$refs.videoObj.$children[0].$refs.input.click();
          } else {
            this.quill.format("video", false);
          }
        });
      
      this.Quill.pasteHTML(this.currentValue);
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        const html = this.$refs.editor.children[0].innerHTML;
        const text = this.Quill.getText();
        const quill = this.Quill;
        this.currentValue = html;
        this.$emit("input", html);
        this.$emit("on-change", { html, text, quill });
      });
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        this.$emit("on-text-change", delta, oldDelta, source);
      });
      this.Quill.on("selection-change", (range, oldRange, source) => {
        this.$emit("on-selection-change", range, oldRange, source);
      });
      this.Quill.on("editor-change", (eventName, ...args) => {
        this.$emit("on-editor-change", eventName, ...args);
      });
    },
    
   async revampLoadShopDoor(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
     
   
      let quill = this.Quill;
      // 如果上传成功
      if (res.code == 200) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image",  res.body[0].split("?")[0]);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      this.$message.success("上传成功");
    },
    // handleUploadSuccess(res, file) {
    //   // 获取富文本组件实例
    //   let quill = this.Quill;
    //   // 如果上传成功
    //   if (res.code == 200) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     // 插入图片  res.url为服务器返回的图片地址
    //     quill.insertEmbed(length, "image", res.url);
    //     // 调整光标到最后
    //     quill.setSelection(length + 1);
    //   } else {
    //     this.$message.error("图片插入失败");
    //   }
    // },
     handleUploadError() {
      this.$message.error("图片插入失败");
    },
    // 添加视频链接
  async addVideoLink(file) {
   const formData = new FormData()
       formData.append("file", file.file);
      const { data: res } = await this.$http.post(`/api/platform/uploadVideo`,formData);
      this.videoObj.videoLink=res.body
      //视频插入在富文本中的位置
      // 当编辑器中没有输入文本时，这里获取到的 newRange 为 null
      let newRange = this.Quill.selection.savedRange;
      let index = 0;
      if (newRange == null) {
        index = 0;
      } else {
        index = newRange.index;
      }
      this.Quill.insertEmbed(index, "video", {
        url: this.videoObj.videoLink,
        controls: "controls",
        width: this.videoObj.videoWidth,
        height: this.videoObj.videoHeight
      });
      //隐藏弹框
      this.videoUploadTag = false;
      this.Quill.setSelection(1 + index);
    }
  }
};
</script>

<style>
/* 富文本框样式，一定要加 */
.editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
}
.quill-img {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

.ql-snow .ql-tooltip[data-mode="video"] {
  left: 15px !important;
}

 .ql-video {
  width: 100%;

} 
</style>
