<template>
  <div class="home">
   <editor></editor>
   <RichText></RichText>
  </div>
</template>

<script>
// @ is an alias to /src
// 引入download.js
import Editor from "@/components/Editor";
import RichText from "@/components/RichTextow";
export default {
  components:{
 Editor,
 RichText
  },
  methods: {
    down() {
      download(
        "http://oss.fuhangbeiyue.com/business_contract/2984027961012867941.pdf"
      );
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
